import React, { useState } from "react";

export function useFOrm(initialFvalues, validateOnChange = false, validate) {
  const [values, setValues] = useState(initialFvalues);
  const [errors, setErrors] = useState({});
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'categoryId') {
      setValues(prevValues => ({
        ...prevValues,
        [name]: value,
        skillId: '' // Reset the skillId value when categoryId changes
      }));
    } else {
      setValues(prevValues => ({
        ...prevValues,
        [name]: value
      }));
    }
    // setValues({
    //   ...values,
    //   [name]: value,
    // });
    if (validateOnChange) {
      validate({ [name]: value });
    }
  };

  const resetForm = () => {

    console.log("test", initialFvalues)
    setValues(initialFvalues);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    resetForm,
    handleInputChange,
  };
}

export function Form(props) {
  const { children, ...other } = props;
  return (
    <form autoComplete="off" {...other}>
      {props.children}
    </form>
  );
}
